import { EventBus } from "@/utils/event-bus.js";
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import store from "@/store/index.js";
import {
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BButton,
  BPagination,
} from "bootstrap-vue";

import { BIconX } from "bootstrap-vue";
// import { faCoffee } from '@fortawesome/fontawesome-free-solid'

export default {
  name: "sidebar-right",
  data: () => {
    return {
      userProfileOpen: false,
      tabIndex: 0,
      tabCaptions: [
        "Firma-opplysninger",
        "Lagrede faste ruter",
        "Abonnement på områder",
        "Dine opplysninger",
        "Tilbud på dine oppdrag",
        "Bestilte oppdrag"
      ],

      items: [
        {
          isActive: true,
          nextStart: "2017-11-19",
          name: { first: "Kristiansand", last: "Oslo" }
        },
        {
          isActive: false,
          nextStart: "2017-11-19",
          name: { first: "Lillestøm", last: "Gjøvik" }
        },
        {
          _rowVariant: "success",
          isActive: false,
          nextStart: "2017-11-21",
          name: { first: "Stavanger", last: "Flekkefjord" }
        },
        {
          isActive: false,
          nextStart: "2017-11-25",
          name: { first: "Arendal", last: "Kristiansand" }
        },
        {
          isActive: true,
          nextStart: "2017-11-30",
          name: { first: "Kristiansand", last: "Flekkefjord" }
        },
        {
          isActive: false,
          nextStart: "2017-11-23",
          name: { first: "Mandal", last: "Risør" }
        },
        {
          isActive: true,
          nextStart: "2017-11-21",
          name: { first: "Porsgrunn", last: "Seljord" }
        },
        {
          _cellVariants: { nextStart: "danger", isActive: "warning" },
          isActive: true,
          nextStart: "2017-11-26",
          name: { first: "Trondheim", last: "Ålesund" }
        },
        {
          isActive: false,
          nextStart: "2017-12-02",
          name: { first: "Haugesund", last: "Bergen" }
        },
        {
          isActive: false,
          nextStart: "2017-11-30",
          name: { first: "Bergen", last: "Ålesund" }
        },
        {
          isActive: true,
          nextStart: "2017-12-01",
          name: { first: "Bodø", last: "Narvik" }
        },
        {
          isActive: false,
          nextStart: "2017-11-29",
          name: { first: "Narvik", last: "Tromsø" }
        }
      ],
      fields: [
        { key: "name", label: "Strekning", sortable: true },
        {
          key: "nextStart",
          label: "Neste tid",
          sortable: true,
          class: "text-center"
        },
        { key: "isActive", label: "Aktiv" },
        { key: "actions", label: "..." }
      ],

      form: {
        email: "",
        name: "",
        companyType: null,
        checked: false,
        secret: "S3CR3T"
      },
      companyType: [
        { text: "Velg en...", value: null },
        "Bud/Express",
        "Flyttebyrå",
        "Buss-selskap",
        "Sjøtransport",
        "Lufttransport"
      ],

      currentPage: 1,
      perPage: 5,
      totalRows: 12, // items.length,
      pageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 15, value: 15 }
      ],
      sortBy: null,
      sortDesc: false,
      filter: null,
      modalDetails: { index: "", data: "" },

      itemsSubscript: [
        {
          isActive: true,
          type: "zip",
          name: "Kongsberg - Hemsedal",
          criteria: "Max 20kg",
          autoQuote: true
        },
        {
          isActive: true,
          type: "polygon",
          name: "Drammen og omegn",
          criteria: "Esker eller paller",
          autoQuote: true
        },
        {
          _rowVariant: "success",
          isActive: true,
          type: "polygon",
          name: "Ringerike",
          criteria: "Max 50 kg, Max 1m<sup>3</sup>",
          autoQuote: true
        },
        {
          isActive: true,
          type: "zip",
          name: "Akershus og Østfold",
          criteria: "",
          autoQuote: true
        },
        {
          isActive: true,
          type: "zip",
          name: "Oslo-området",
          criteria: "Kun flyttelass",
          autoQuote: false
        }
      ],
      fieldsSubscript: [
        { key: "name", label: "Områdenavn", sortable: true },
        { key: "type", label: "Område pr.", sortable: true },
        { key: "criteria", label: "Kriterier", sortable: true },
        { key: "autoQuote", label: "Auto-tilbud" },
        { key: "actions", label: "..." }
      ],

      currentPageSubscript: 1,
      perPageSubscript: 5,
      totalRowsSubscript: 12, // items.length,
      sortBySubscript: null,
      sortDescSubscript: false,
      filterSubscript: null,

      itemsWaitOffer: [
        {
          isActive: true,
          type: "furniture",
          name: "Sofa / Skien -> Elverum",
          offers: "2",
          notification: true
        },
        {
          isActive: true,
          type: "embalage",
          name: "2 esker / Kragerø -> Kristiansand",
          offers: "5",
          notification: true
        },
        {
          _rowVariant: "success",
          isActive: true,
          type: "embalage",
          name: "Sykkelutstyr / Oslo -> Bergen",
          offers: "4",
          notification: true
        }
      ],
      fieldsWaitOffer: [
        {
          key: "name",
          label: "Oppdrag",
          sortable: true
        },
        {
          key: "type",
          label: "Kategori",
          sortable: true
        },
        {
          key: "isActive",
          label: "Status",
          sortable: true
        },
        {
          key: "offers",
          label: "Antall tilbud"
        },
        {
          key: "notification",
          label: "Få varsler ?"
        }
      ],

      currentPageWaitOffer: 1,
      perPageWaitOffer: 5,
      totalRowsWaitOffer: 12, // items.length,
      sortByWaitOffer: null,
      sortDescWaitOffer: false,
      filterWaitOffer: null,

      itemsAssignment: [
        {
          actions: true,
          status: "Levert",
          name: "PC-utstyr / Kongsberg -> Trondheim",
          type: "embalage"
        },
        {
          actions: true,
          status: "I transport",
          name: "LP-plater / Narvik -> Alta",
          type: "embalage"
        }
      ],
      fieldsAssignment: [
        { key: "name", label: "Oppdrag", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "type", label: "Kategori", sortable: true },
        { key: "actions", label: "..." }
      ],

      currentPageAssignment: 1,
      perPageAssignment: 5,
      totalRowsAssignment: 2, // items.length,
      sortByAssignment: null,
      sortDescAssignment: false,
      filterAssignment: null
    };
  },
  components: {
    "b-tabs": BTabs,
    "b-tab": BTab,
    "b-form": BForm,
    "b-form-group": BFormGroup,
    "b-form-input": BFormInput,
    "b-form-checkbox": BFormCheckbox,
    "b-button": BButton,
    "b-form-select": BFormSelect,
    "b-pagination": BPagination,
    BIconX
  },
  computed: {
    isCompany() {
      return store.getters["account/isCompany"];
    }
  },
  mounted: function() {
    console.info("Side-bar created !");

    EventBus.$on("openUserProfile", () => {
      this.openUserprofile();
    });
  },
  beforeDestroy() {
    EventBus.$off("openUserProfile");
  },
  methods: {
    openUserprofile() {
      this.userProfileOpen = !this.userProfileOpen;
    },
    details(item, index, button) {
      this.modalDetails.data = JSON.stringify(item, null, 2);
      this.modalDetails.index = index;
      this.$root.$emit("bv::show::modal", "modal1", button);
    },
    resetModal() {
      this.modalDetails.data = "";
      this.modalDetails.index = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredSubscript(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsSubscript = filteredItems.length;
      this.currentPageSubscript = 1;
    },
    onFilteredWaitOffer(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsWaitOffer = filteredItems.length;
      this.currentPageWaitOffer = 1;
    },
    onFilteredAssignment(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsAssignment = filteredItems.length;
      this.currentPageAssignment = 1;
    },

    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    logout() {
      store.dispatch("account/logout", {});
      this.openUserprofile();
    }
  }
};
